// @import 'utils';
.legal {
  max-width: 1024px;
  margin: 0 auto 4em;
  padding: 0 2em 4em;

  margin-bottom: 10em;
  .danger {
    color: red;
  }
  .long {
    height: 50em;
  }
  br {
    line-height: 2em;
  }

  a {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  span:first-child {
    color: #666;
    letter-spacing: -0.01em;
    padding-right: 0.5em;
  }
  strong {
    font-variation-settings: 'wght' 600;
  }
  header {
    padding: 4em 0 2em;
  }
  h1 {
    text-transform: uppercase;
    font-size: 3em;
    font-variation-settings: 'wght' 600;
    margin: 0;
    letter-spacing: 0.05em;
  }
  h2 {
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 2em;
    font-weight: bold;
    padding: 2em 0 1em;
    margin: 0;
    font-variation-settings: 'wght' 400;
    & + h3 {
      padding-top: 0;
    }
  }
  h3 {
    font-size: 1.5em;
    padding: 2em 0 1em;
    margin: 0;

    &:first-child {
      padding-top: 0;
    }
    & + h4 {
      padding-top: 0;
    }
  }
  h4 {
    font-size: 1em;
    padding: 2em 0 1em;
    margin: 0;
    font-variation-settings: 'wght' 400;
    // padding-left: 0.5em;
    // & + p {
    //   padding-left: 0.5em;
    // }
  }
  h6 {
    font-size: 1em;
    font-weight: 500;
    padding: 2em 0 1em;
    margin: 0;
  }

  p {
    margin: 0;
  }

  li {
    list-style: none;
    position: relative;
    margin-bottom: 0.5em;
    margin: 0;
  }
  nav {
    margin-bottom: 3em;
    ul {
      padding-left: 0;
      ul {
        padding-left: 1.5em;
      }
      li {
        cursor: pointer;
        margin-bottom: 0.75em;
        &:hover span:last-child {
          text-decoration: underline;
        }
      }
    }
  }

  section {
    h4 + ul {
      margin-top: 0;
    }
    > ul {
      margin: 1em;
      padding: 0.5em;
      li::before {
        content: '-';
        position: absolute;
        left: -0.75em;
      }
    }
  }
}
