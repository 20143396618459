// app-modal {
//   &.header.body {
//     article.be-modale {
//       display: grid;
//       grid-template-rows: auto auto 1fr auto;
//       grid-column-gap: 1em;
//       // grid-template-rows: 6rem calc(100% - 6rem)
//     }
//   }
// }

.be-overlay {
  @extend .popInOverlay;
}
article.be-modale {
  max-height: 90%;
  height: 90%;
  min-width: 768px;
  width: 1024px;
  @extend .maxWidth;
  @extend .card-shadow;

  display: grid !important;
  grid-template-rows: auto auto 1fr auto;
  grid-column-gap: 1em;

  @include screen-tablet {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: 100%;
  }

  > header {
    width: 100%;
    padding: 2em;
    background-color: $default-primary;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin-bottom: 0;
      color: #fff;
    }
  }

  .be-modale_toolbar {
    position: relative;
    z-index: 1;
    box-shadow: 0 1px 0.2em rgba(#000, 0.1);

    background: $default-grey;
    > div {
      position: relative;
      padding-top: 1em;
    }
    .optionBar {
      padding: 1em 2em 0;
      margin: 0;
      justify-content: space-between;
      > * {
        margin-bottom: 2em;
      }
      &.solo {
        display: flex;
        justify-content: center;
        align-items: center;
        ng-select {
          width: 100%;
        }
      }
    }

    .disclaimer {
      position: absolute;
      top: 100%;
      width: 100%;
      background: $default-danger;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5em;
      left: 0;
      transform: translateY(-100%);
      z-index: -1;
      transition: all 0.3s ease-in-out;

      &.active {
        transform: translateY(0);
      }

      p {
        line-height: 1em;
        margin-bottom: 0;
        color: #fff;
      }
    }
  }

  main.be-modale_content {
    @extend .scrollBox;
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0 4em;
    @include screen-tablet {
      padding: 0 2em;
    }
  }

  > footer {
    color: #fff;
    background: $default-primary;

    > * {
      padding: 2em;
    }
  }
}

.close-modale {
  font-size: 2em;
  color: #fff;
  cursor: pointer;
}
