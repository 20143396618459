@import './thema.scss';

a {
  text-decoration: none;
}

.login-logo {
  bottom: 50%;
  left: 35%;
  position: absolute;
}

.side-nav {
  .side-nav-inner {
    .side-nav-logo {
      padding: 0px 0px 0px 90px;
    }
  }
}

.blur-nohover {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  z-index: 1;
}

.blur {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  z-index: 1;
}

.blur:hover {
  filter: blur(0px);
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
}

// FORCING //
.ngx-datatable {
  height: 100%;

  datatable-row-wrapper {
    background-color: $default-grey;

    &:nth-child(even) {
      background-color: #fff;
    }
  }

  //datatable-row-center,
  //datatable-header-inner,
  datatable-header,
  datatable-header-cell {
    height: 40px;
    height: 5em !important;

    .datatable-header-cell-template-wrap {
      display: flex;
      align-items: center;
    }
  }

  .page-count {
    display: none;
  }

  datatable-body-cell,
  datatable-header-cell {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 0.25em;
    position: relative;
  }

  img {
    height: 2em;
  }

  .risk-badge {
    font-size: 1em;
  }

  .datatable-footer .datatable-pager .pager,
  .datatable-footer .datatable-pager .pager li {
    display: inline-flex !important;
    gap: 0.2em;
    .pages {
      a {
        color: $default-text-color !important;
        padding: 0 0.4em;
        border-radius: 0.2em;
        transition: background-color 0.2s ease;
      }
      @include active {
        a {
          color: #fff !important;
          background-color: $default-primary;
        }
      }
    }
  }
}

.be-avatar .avatar-content {
  background-color: #4675b8 !important;
}

.container-fluid {
  padding-top: 2em;
}

.card {
  margin-bottom: 0;
  //@extend .card-shadow;
  padding: 2em;
  > info-tooltip {
    position: absolute;
    right: 1em;
    top: 1em;
  }
  .ngxp__inner {
    p {
      margin-top: 0;
      color: #fff;
    }
  }

  .card-title {
    margin: 0 0 2.5em 0;

    h3 {
      font-size: 1.5em;
      margin-bottom: 0;
    }
  }
}

.be-like {
  &.active {
    color: $default-danger;
  }
}

.ei-heart-full {
  color: $default-danger;
}

.fav {
  display: flex;
  cursor: pointer;

  &:hover,
  &:focus,
  &:active {
    transition: all 0.25s ease-in-out;
    color: $default-danger;
  }

  i,
  span {
    font-size: 1.2em;
    line-height: 1em;
    color: $default-danger;
  }

  span {
    margin: 0 0.2em;
  }
}

.rank {
  color: $default-dark;
  font-size: 1em;
}

.overlay-img {
  @extend .center;
}

.custom-switch > * {
  cursor: pointer;
}

p {
  line-height: 1.5;
}

// browser update ribbon
body {
  .buorg {
    font-size: 1em;
    @extend .font-primary;
    background-color: #fff;
    box-shadow: none;
    color: #666;
  }

  .buorg-pad {
    padding: 2em;
    line-height: 1.5em;
    b {
      font-weight: normal;
    }
  }

  .buorg-buttons {
    margin-top: 0.5em;
  }

  #buorgig,
  #buorgul,
  #buorgpermanent {
    box-shadow: none;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    color: $default-success;
  }

  #buorgig {
    background-color: transparent;
    color: #666;
  }

  @media only screen and (max-width: 700px) {
    .buorg div {
      padding: 5px 12px 5px 9px;
      line-height: 1.3em;
    }
  }

  @keyframes buorgfly {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  .buorg-fadeout {
    transition: visibility 0s 8.5s, opacity 8s ease-out 0.5s;
  }

  .buorg-icon {
    width: 22px;
    height: 16px;
    vertical-align: middle;
    position: relative;
    top: -0.05em;
    display: inline-block;
    background: no-repeat 0px center;
  }
}
/*light bg version*/

body.light_version {
  background-color: #f7f7f7;

  header.page-title > h1,
  section > header > h1 {
    color: $default-primary ;
  }
  header.page-title > p,
  section > header > p {
    color: lighten($default-text-color, 10%) !important;
  }

}
.page {
  > section {
    background: rgba(#000, 0.05);
    padding: 2em;
    .flex-title {
      margin-top: 0;
    }
    .page-title {
      font-size: 1.3em;
      h1 {
        @extend .font-secondary;
        text-transform: uppercase;
      }
    }
  }
}

/*light bg version End*/
