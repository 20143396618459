button,
input,
textarea {
  outline: none;
}

input,
.ng-select .ng-select-container,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  background-color: #fff;
  // background-color: #f5f5f5;
  border: 1px solid rgba($default-text-color, 0.3);
  border-radius: 0.2rem;
}

input,
.ng-select .ng-select-container {
  padding: 0.5em 1em;
  min-height: 3em;
}

form {
  .btn {
    display: block;
    width: auto;
    min-width: 33.33%;
    min-height: 3em;
    span {
      font-size: 1.2em;
    }
    text-transform: capitalize;
  }
}

input {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-align: start;
  appearance: textfield;
  cursor: text;
  margin: 0em;
  line-height: 1.15;
}

.form-group {
  margin-bottom: 1em;
  display: grid;
  grid-template-columns: 8em auto;
  grid-gap: $grid-gap;
  align-items: center;
  label {
    text-align: right;
  }
}
.form-control {
  border: 1px solid $border-color;
  border-radius: 2px;
  box-shadow: none;
  font-size: 14px;
  color: $default-text-color;
  height: 3em;
  @include transition(all 0.2s ease-in);
  @include placeholder {
    color: lighten($default-text-color, 30%);
  }

  &:focus {
    outline: 0 none;
    box-shadow: none;
    border-color: lighten($default-info, 10%);
  }

  &.input-sm {
    height: 30px;
  }

  &.input-lg {
    height: 50px;
  }

  &.error {
    border-color: $default-danger;
    background-color: $inverse-danger;
  }

  &.valid {
    border-color: $default-success;
    background-color: $inverse-success;
    color: darken($default-success, 15%);
  }
}

input[type='checkbox'] {
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;

  + label {
    color: $default-text-color;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 1em;
    padding: 0.5em 0.5em 0.5em 0;
    &:before {
      cursor: pointer;
      background-color: $default-grey;
      border: 1px solid rgba($color: $default-dark, $alpha: 0.1);
      border-color: #dbdbdb;
      border-radius: 0.1em;
      width: 1.2em;
      height: 1.2em;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Material Symbols Rounded';
      font-style: normal;
      font-size: 1.2em;
      content: '';
      margin-right: 0.5em;
      color: $default-dark;
    }
  }
  &:checked + label {
    &:before {
      content: 'done';
    }
  }

  &.toggle + label {
    padding: 0;
    &:before {
      margin-right: 0;
      border: none;
      content: 'toggle_off';
      color: $default-text-color;
    }
  }
  &.toggle:checked + label:before {
    border: none;
    content: 'toggle_on';
    color: $default-success;
  }
}

.boost-input_form-group,
.boost-select_form-group {
  display: grid;
  grid-template-rows: 2em 3em 1.5em;
  margin: 1em 0;

  label {
    color: $default-text-color;
  }
  > div {
    width: 100%;
    display: flex;
    position: relative;
    &.hasIcon {
      input {
        padding-right: 15%;
      }
    }
  }
  p {
    color: $default-danger;
    line-height: 0.5em;
    margin: 0;
    display: flex;
    align-items: flex-end;
    font-size: 0.8em;
  }
  .boost-input_form-control-icon {
    display: flex;
    width: 15%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: 0;
    height: 100%;
    span {
      font-size: 1.4em;
    }
  }
  input {
    width: 100%;
    min-height: 100%;
    max-height: 100%;
    color: #000;
    font-size: 1.1em;
  }
  .ng-value {
    color: #000;
  }
}
.boost-select_form-group {
  grid-template-rows: 2em auto 1em;
}
boost-submit-button {
  display: flex;
  justify-content: flex-end;
}

form.authentication_form {
  input,
  .ng-select .ng-select-container {
    min-height: 4em;
  }
  .btn {
    min-height: 4em;
  }
  .boost-input_form-group,
  .boost-select_form-group {
    grid-template-rows: 2em 4em 1.5em;
  }
}
