@import "utils"; // Mixins & Variables
*,
*:before,
*:after {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
:root {
	--default-primary: #fff;
}

body {
	background-color: var(--default-primary);
}

#main-logo i {
	color: var(--default-primary);
}

.be-bg {
	//background-color: $default-primary;
	//background: fixed linear-gradient(125deg, darken($default-primary, 15%) 0%, $default-primary 25%, $default-primary 100%);
	//background: fixed linear-gradient(to top left, $risk-1, $risk-2);

	//background: fixed linear-gradient(to top left, rgba(#000,.1), rgba(#fff,.1));

	/* IE10 Consumer Preview */
	//background-image: -ms-linear-gradient(bottom, #EDD799 0%, #BF7F37 100%);
	/* Mozilla Firefox */
	//background-image: -moz-linear-gradient(bottom, #EDD799 0%, #BF7F37 100%);
	/* Opera */
	//background-image: -o-linear-gradient(bottom, #EDD799 0%, #BF7F37 100%);
	/* Webkit (Safari/Chrome 10) */
	//background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #EDD799), color-stop(1, #BF7F37));
	/* Webkit (Chrome 11+) */
	//background-image: -webkit-linear-gradient(bottom, #EDD799 0%, #BF7F37 100%);
	/* W3C Markup, IE10 Release Preview */
	//background-image: linear-gradient(to top, #EDD799 0%, #BF7F37 100%);
	background: fixed
		linear-gradient(to top left, hsl(216, 45%, 50%), hsl(216, 50%, 55%));
}

body {
	// @extend .be-bg;
	margin: 0;
	overflow: hidden;
}

// THEMA //
.backgroundRisk {
	color: #fff;

	&[class*="risk-FE"] {
		background-color: $default-primary;
	}
	&[class*="risk-null"] {
		background-color: $default-primary;
	}

	&[class*="risk-0"] {
		background-color: $risk-0;
	}

	&[class*="risk-1"] {
		background-color: $risk-1;
	}

	&[class*="risk-2"] {
		background-color: $risk-2;
	}

	&[class*="risk-3"] {
		background-color: $risk-3;
	}

	&[class*="risk-4"] {
		background-color: $risk-4;
	}

	&[class*="risk-5"] {
		background-color: $risk-5;
	}

	&[class*="risk-6"] {
		background-color: $risk-6;
	}

	&[class*="risk-7"] {
		background-color: $risk-7;
	}
}

.colorRisk {
	&[class*="risk-FE"] {
		color: $default-primary;
	}
	&[class*="risk-null"] {
		color: $default-primary;
	}
	&[class*="risk-0"] {
		color: $risk-0;
	}

	&[class*="risk-1"] {
		color: $risk-1;
	}

	&[class*="risk-2"] {
		color: $risk-2;
	}

	&[class*="risk-3"] {
		color: $risk-3;
	}

	&[class*="risk-4"] {
		color: $risk-4;
	}

	&[class*="risk-5"] {
		color: $risk-5;
	}

	&[class*="risk-6"] {
		color: $risk-6;
	}

	&[class*="risk-7"] {
		color: $risk-7;
	}
}

.borderRisk {
	&[class*="risk-FE"] {
		border-color: $default-primary;
	}
	&[class*="risk-null"] {
		border-color: $default-primary;
	}
	&[class*="risk-0"] {
		border-color: $risk-0;
	}

	&[class*="risk-1"] {
		border-color: $risk-1;
	}

	&[class*="risk-2"] {
		border-color: $risk-2;
	}

	&[class*="risk-3"] {
		border-color: $risk-3;
	}

	&[class*="risk-4"] {
		border-color: $risk-4;
	}

	&[class*="risk-5"] {
		border-color: $risk-5;
	}

	&[class*="risk-6"] {
		border-color: $risk-6;
	}

	&[class*="risk-7"] {
		border-color: $risk-7;
	}
}

*[class*="c-risk"] {
	color: #fff;
	@extend .colorRisk;
}

*[class*="bg-risk"] {
	color: #fff;
	@extend .backgroundRisk;
}

//card-top-border-risk-1
*[class*="border-risk"] {
	$width: 1em;

	border-top-width: 0;
	border-left-width: 0;
	border-right-width: 0;
	border-bottom-width: 0;

	&[class*="top-border"] {
		border-top-width: $width;
		border-radius: 0 0 0.25rem 0.25rem;
	}

	&[class*="left-border"] {
		border-left-width: $width;

		border-radius: 0 0.25rem 0.25rem 0;
	}

	@extend .borderRisk;
}

.color-primary {
	color: $default-primary;
}

.color-danger {
	color: $default-danger;
}

.color-success {
	color: $default-success;
}

.show {
	display: block;
}

.hide {
	display: none;
}

.risk-badge {
	@extend .center;
	border-radius: 50%;
	width: 2em;
	height: 2em;
	@extend .font-alt;
	line-height: 0.5em;
	box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);

	&.active {
		transform: translate3d(0, 0, 0);
		transition: transform 0.1s ease-in-out;
		@include hover {
			box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
			transform: translateY(-2px);
		}
	}
}
.danger-link {
	cursor: pointer;
	border-bottom: 1px solid $default-text-color;
	margin-right: 1em;
	@include hover {
		transition: all 0.2s ease-out;
		border-bottom: 1px solid $default-danger;
		color: $default-danger !important;
	}
}

.optionBar {
	display: flex;
	align-items: center;
	justify-content: space-around;
	background: $default-grey;
	padding: 1em;
	margin: 2em 0;
	flex-wrap: wrap;

	ng-select {
		min-width: 15em;
	}

	.reset {
		@extend .risk-badge;
		cursor: pointer;
		font-family: Arial, sans-serif;
		color: $default-text-color;
		box-shadow: none;
	}
}

.echelleRisk {
	display: flex;
	align-items: center;

	@include screen-mobile {
		display: none;
	}

	div {
		display: flex;
		margin-left: 0.5em;
	}

	span,
	risk-badge {
		margin-left: 0.25em;
	}

	&.riskMenu {
		span,
		risk-badge {
			cursor: pointer;
			background-color: transparent;
			@extend .colorRisk;
			border: 2px solid transparent;
			@extend .borderRisk;

			&.bg-risk-0 {
				padding: 0 0.5em;
				text-transform: uppercase;
				margin-right: 0.5em;
				display: flex;
				align-items: center;
			}

			&:hover,
			&:active,
			&:focus,
			&.active {
				@extend .backgroundRisk;
				color: #fff;
			}
		}
	}
}

[class*="grid"] {
	display: grid;
	grid-template-rows: auto;
	grid-gap: $grid-gap;
	grid-auto-flow: dense;

	&[class*="grid-demi"] {
		@include md {
			grid-template-columns: 1fr;
			height: auto;
		}

		@include lg {
			grid-template-columns: 1fr 1fr;
		}
	}

	&[class*="grid-tiers"] {
		@include md {
			grid-template-columns: 1fr;
			height: auto;
		}

		@include lg {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}

	&[class*="grid-quart"] {
		@include md {
			grid-template-columns: 1fr 1fr;
			height: auto;
		}

		@include lg {
			grid-template-columns: 1fr 1fr 1fr 1fr;
		}
	}

	&[class*="grid-sixieme"] {
		@include md {
			grid-template-columns: 1fr 1fr 1fr;
			height: auto;
		}

		@include lg {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		}
	}

	> *.col-2 {
		grid-column: span 2;
	}
	> *.col-3 {
		grid-column: span 3;
	}
	> *.row-2 {
		grid-row: span 2;
	}
	> *.row-3 {
		grid-row: span 3;
	}
}

.supports-table {
	header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 4em;

		h4 {
			margin-bottom: 0;
			font-size: 1.5em;
		}
	}
	.datatable-header-cell {
		color: #515365;
		border-bottom: 1px solid #e6ecf5;
		@extend .font-secondary;
	}

	.datatable-row-group {
		background-color: #fff;
		&:hover {
			background-color: $default-grey;
		}
	}
	.pointer {
		cursor: pointer;
	}
	.table-col-grey-header {
		background-color: $default-grey;
	}

	.table-col-grey-cell {
		background-color: $default-grey;
		.datatable-body-cell-label {
			width: 100%;
			padding: 1em 1.5em;
		}
	}
	.table-col-left-align {
		display: flex !important;
		justify-content: flex-start;
		.datatable-body-cell-label {
			text-align: left;
			text-transform: uppercase;
		}
	}
}
///
/*
.supports-table {
  header {
    display: flex;
    justify-content: space-between;

    h4 {
      margin-bottom: 0;
      font-size: 1.5em;
    }
  }

  .table-overflow {
    padding: 4em 0 0;
  }

  table {
    table-layout: auto;
    text-align: center;

    th,
    td {
      position: relative;
      padding: 1.5em;
      line-height: 1.5em;
      vertical-align: middle;
    }

    th:nth-child(2),
    td:nth-child(2) {
      text-align: left;
    }

    th:nth-child(1),
    td:nth-child(1) {
      width: 3em;
      text-align: center;

      .risk-badge {
        margin: 0 auto;
      }
    }

    td:nth-child(3),
    td:nth-child(4) {
      padding: 0 1em;

      > div {
        display: flex;
        align-content: center;

        > div:first-child {
          width: 66.66%;
          .progress {
            margin: 0 auto;
            margin-bottom: 0;
            width: 90%;
            max-width: 20em;
          }
        }

        > div:last-child {
          width: 33.33%;
          line-height: 1em;
        }
      }
    }

    th:nth-child(3),
    td:nth-child(3) {
      width: 30%;
      background-color: $default-grey;
    }
    td:nth-child(4) {
      width: 20%;
    }

    td:last-child {
      > div {
        height: 1em;
      }
    }

    .be-arrow-right {
      position: absolute;
      right: 0;
      left: 0;
    }

    &.table-hover > tbody > tr:hover {
      > td:nth-child(3) {
        background-color: #fff;
      }

      > td:last-child span {
        opacity: 1;

        &.be-arrow-right {
          @extend .animationFleche;
        }
      }
    }
  }
}
*/

@keyframes slideInLeft {
	from {
		transform: translateX(-40%);
		opacity: 0.5;
	}

	to {
		transform: translateX(0);
		opacity: 1;
	}
}

.btnContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	cursor: pointer;

	.be-arrow-right,
	[class*="ei-heart"] {
		font-size: 1.5em;
		line-height: 0.5em;
	}

	.ei-heart {
		color: $default-text-color;
	}

	@include hover {
		.ei-heart {
			color: $default-danger;
		}

		.ei-heart-full {
			color: $default-text-color;
			opacity: 0.7;
		}

		.be-arrow-right {
			@extend .animationFleche;
		}
	}
}

// .loading-box {
//   padding: 4em 2em 2em;
//   display: flex;
//   gap: 2em;

//   min-height: 10em;
//   height: 100%;
//   @extend .center;
//   font-size: 1em;
//   color: $default-primary;

//   p {
//     color: $default-primary;
//     text-transform: uppercase;
//     opacity: 0.6;
//   }

//   &.cover {
//     position: absolute;
//     background-color: #fff;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     z-index: 100;
//     min-height: 0;
//   }
// }

.fallback.empty_tiers_card {
	height: 15em;
	cursor: pointer;
	background-image: url("#{$assetPath}/fake-tiers.png");
	background-size: cover;
	background-position: center;

	@extend .card-shadow;
	span {
		font-size: 5em;
		color: $default-primary;
	}
}
.popInOverlay {
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	z-index: 1000;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	background: rgba(#000, 0.8);

	&.closed {
		display: none;
	}

	&.open {
		display: flex;
	}
	article {
		position: relative;
		border-radius: 0.25em;
		overflow: hidden;
		background: #fff;
	}
}

#search-fund,
#search-contract {
	.be-modale_content > * {
		width: 100%;
	}

	#comparator {
		display: flex;
		justify-content: space-between;
		padding: 1em;
		background: #fff;
		box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
		align-items: center;

		.be-arrow-left:before {
			line-height: 2em;
		}

		cursor: pointer;

		@include hover {
			box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);

			.be-arrow-left {
				color: $default-primary;
			}
		}

		.risk-badge {
			font-size: 0.9em;
		}

		> *:not(:first-child) {
			margin-left: 1em;
		}
	}
}

#search-contract {
	ngx-datatable {
		datatable-body-cell,
		datatable-header-cell {
			&:nth-child(3),
			&:nth-child(4),
			&:nth-child(5) {
				background-color: rgba($default-text-color, 0.1);
			}
		}
	}
}

#search-fund {
	.left-align {
		justify-content: flex-start;
	}
	.grey-bg {
		background-color: rgba($default-text-color, 0.1);
		datatable-body-cell,
		datatable-header-cell {
			background-color: rgba($default-text-color, 0.1);
		}
	}
	ngx-datatable {
		datatable-body-cell {
			white-space: normal;
			padding: 1.5em;

			&:nth-child(1) {
				text-transform: capitalize;
			}

			/* &:nth-child(1),
      //&:nth-child(2),
      &:nth-child(3) {
        justify-content: flex-start;
      }*/
		}

		/* datatable-body-cell,
    datatable-header-cell {
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        background-color: rgba($default-text-color, 0.1);
      }
    }*/
		.datatable-footer .datatable-pager {
			.pager {
				li {
					margin-left: 1em;
				}
			}
		}
	}
}

#add-account,
#edit-account {
	.be-modale_content {
		//@extend .center;
	}

	.wrapper {
		min-width: 50%;
	}
}

.budgetInsight {
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		max-height: 3em;
	}

	> div:first-child {
		text-align: center;
	}

	> div:last-child {
		padding: 0 1.5em;
		max-width: 60em;
		font-size: 0.8em;
	}
}

.manage_toolbar {
	margin-top: 2em;
	padding: 1em;
	background-color: $default-grey;
	display: flex;
	justify-content: space-between;

	button.btn {
		margin-bottom: 0;
	}

	.loadBtn {
		display: flex;

		> [class*="spin"] {
			margin-right: 1em;
		}
	}
}

.fallback {
	@extend .center;
	color: $default-text-color;
	min-height: 10em;
	text-align: center;
	padding: $grid-gap;
	background: #fff;
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	h5 {
		color: $default-primary;
		text-transform: uppercase;
		font-size: 1.2em;
		margin-bottom: 0;
	}
	.btn {
		&:not(.primary-outline),
		&:not(.success) {
			@extend .primary;
		}
		text-transform: uppercase;
		min-width: 75%;
		margin-bottom: 1em;
		display: flex;
		align-items: center;
		line-height: 0;
		padding: 1.25em;

		i {
			margin-right: 0.25em;
		}
	}
}

.ngxp__container.tooltip {
	@extend .tooltip;

	.ngxp__arrow {
		border-color: $default-info;
	}
}

#chart-project {
	opacity: 0.95;
	box-shadow: none;
	border: none;
	border-radius: 0.5em;
	background-color: rgba(#fff, 0.8);
	.teaser {
		@extend .fallback;
		background: rgba(#fff, 0.85);
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		position: absolute;
		border-radius: 0.5em;
		padding: 0 !important;
		h5 {
			color: #4675b8;
			text-transform: uppercase;
			font-size: 1.2em;
			margin-bottom: 0;
		}
	}
}
.powered {
	font-size: 13px;
	line-height: 1.15;
}
.chart_grid {
	// opacity: 0.5;

	.tick {
		stroke: lightgrey;
		opacity: 0.3;
	}
	path {
		stroke-width: 0;
	}
}
.close {
	position: absolute;
	top: 0.75em;
	right: 0.75em;
	font-size: 1.2em;
	cursor: pointer;
}
// font-stretch: 85%;
.conError {
	font-size: 0.75em;
	display: block;
	background-color: #f32013;
	position: absolute;
	width: 1em;
	height: 1em;
	top: 0;
	right: 0.1em;
	border-radius: 50%;
	border: 1px solid rgba(#ffffff, 0.5);
}
.addContrat {
	min-height: 3rem;
	align-items: center;
	display: flex;
	color: $default-text-color;
	border: 1px dashed $default-text-color;
	padding: 0.5em;
	border-radius: 0.2em;
	opacity: 0.7;
	// transition: transform 0.1s ease-in-out;
	transition: box-shadow 0.1s ease-in-out;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
	transform: translate3d(0, 0, 0);

	@include hover {
		opacity: 1;
		border: 1px solid $default-success;
		background-color: $default-success;
		color: #fff;
		box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
		transform: translateY(-2px);
	}

	.material-symbols-rounded {
		font-variation-settings: "wght" 500;
		font-size: 1.3em;
		margin-right: 0.25em;
	}
}
.simple-menu {
	list-style: none;
	width: 100%;
	padding-left: 0;

	li {
		width: 100%;
		list-style: none;
		cursor: pointer;
		color: $default-dark;
		text-decoration: none;
		// font-weight: bold;
		// margin-bottom: 1rem;
		padding: 0.5em;
		a {
			width: 100%;
		}

		> span {
			position: relative;
			@include active {
				color: $default-primary;
			}
			.conError {
				right: -1.25em;
				top: -0.25em;
			}
		}

		&:last-child {
			margin-top: 2rem;
		}
		&:not(.special) {
			@include active {
				background-color: #f5f5f5;
				border-radius: 0.5em;
			}
		}
	}
}
