.progress-bar {
  background-color: rgba(#000, 0.05);
  height: 1em;
  width: 100%;
  display: flex;
  border-radius: 0.1em;
  overflow: hidden;
}

.progress-bar_bar {
  background-color: $default-primary;
  height: 100%;
  width: 0;
  transition: all 0.7s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
}
