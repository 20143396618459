.loader {
  $loader-color: #fff;
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 0.2em solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;

  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  &.primary {
    $loader-color: $theme-primary;
  }

  &.full {
    border-left-color: rgba($loader-color, 0.4);
    border-right-color: rgba($loader-color, 0.4);
    border-bottom-color: rgba($loader-color, 0.4);
  }

  border-top-color: $loader-color;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
