button {
  outline: none;
  box-shadow: none;
  cursor: pointer;
  display: flex !important;
  justify-content: center;
  &:focus {
    outline: none;
  }

  &.active,
  &:active {
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &.disabled,
  &:disabled {
    background-color: #f5f5f5 !important;
    color: rgba(#7a7a7a, 0.7) !important;
    border-color: rgba(#dbdbdb, 0.5) !important;
    cursor: not-allowed;
    box-shadow: none;
  }
}

/*
BUTTONS
*/
@mixin btn-theme($btn-color) {
  border-color: transparent;
  background-color: $btn-color;
  color: #fff;
  .loader {
    border-top-color: #fff;
  }

  @include hover {
    &:not(.disabled):not(:disabled) {
      color: rgba(#fff, 0.9);
      background-color: darken($btn-color, 5%);
    }
  }
  &.disabled,
  &:disabled {
    //background-color: lighten($btn-color, 10%);
  }
}
@mixin btn-theme-outline($btn-color) {
  text-transform: capitalize;
  border-color: $btn-color;
  color: $btn-color;
  box-shadow: none;

  @include hover {
    background-color: $btn-color;
    color: #fff;
    .loader {
      border-top-color: #fff;
    }
  }
}
.btn-subtle{
  display: inline-block;
  cursor: pointer;
  color:$default-text-color;
  text-decoration: none;
  padding-bottom: 0.25em;
  border-bottom: 1px solid $default-text-color;
  @include hover {
    transition: all 0.2s ease-in-out;
    color: $default-dark;
    border-color: $default-dark;
  }

}
.btn {
  border-radius: 0.2em;
  border: 1px solid $default-grey;
  box-shadow: 1px 1px 1px rgba(#000, 0.1);
  padding: 0.75em 1em;
  @extend .font-cta;
  font-size: 1.1em;
  cursor: pointer;
  text-transform: capitalize;
  letter-spacing: 0.05em;
  background-color: #fff;
  display: inline-flex;
  align-items: center;
  width: auto;
  justify-content: center;

  .loader {
    margin-right: 0.5em;
  }

  @include hover {
    transition: all 0.2s ease-in-out;
  }

  &.primary {
    @include btn-theme($default-primary);
  }
  &.primary-outline {
    @include btn-theme-outline($default-primary);
  }

  &.success {
    @include btn-theme($default-success);
  }
  &.success-outline {
    @include btn-theme-outline($default-success);
  }

  &.danger {
    @include btn-theme($default-danger);
  }
  &.danger-outline {
    @include btn-theme-outline($default-danger);
  }

  &.info {
    @include btn-theme($default-info);
  }
  &.info-outline {
    @include btn-theme-outline($default-info);
  }
  &.warning {
    @include btn-theme($default-warning);
  }
  &.info-warning {
    @include btn-theme-outline($default-warning);
  }

  &.neutral {
    @include btn-theme($default-grey);

    color: $default-text-color;
    @include hover {
      &:not(.disabled):not(:disabled) {
        color: rgba($default-text-color, 0.9);
      }
    }
  }

  &.fav {
    border-color: $default-danger;
    max-width: 15em;
    display: flex;
    justify-content: center;

    span {
      margin: 0 0 0 0.4em;
      font-size: 1.2em;
    }

    @include hover {
      background-color: $default-danger;

      .ei-heart-full,
      span {
        color: #fff;
      }
    }

    &.active {
      background-color: $default-danger;

      i,
      span {
        color: #fff;
      }

      @include hover {
        background-color: #fff;

        i,
        span {
          color: $default-danger;
        }
      }
    }
  }
}

/*
CTA
*/

@mixin cta-theme($btn-color) {
  background-color: $btn-color;
  color: #fff;
  @include hover {
    background-color: darken($btn-color, 3%);
  }
}

.cta {
  display: inline-flex;
  align-items: center;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  padding: 0;
  text-transform: uppercase;
  cursor: pointer;
  @include hover {
    box-shadow: inherit;
  }
  span {
    &:nth-child(1) {
      background: rgba(#000, 0.3);
      padding: 0.5em;
    }
    &:nth-child(2) {
      padding: 0.75em 0 0.75em 0.75em;
    }
    &:nth-child(3) {
      padding: 0.5em;
    }
  }

  &.primary {
    @include cta-theme($default-primary);
  }
  &.danger {
    @include cta-theme($default-danger);
  }
}
.link {
  text-decoration: none;
  @include hover {
    text-decoration: underline;
  }
}
