/*---------------------------------------------------------------------------
  Template: Espire - Angular Web App Admin Template
  Author: Theme_Nate
----------------------------------------------------------------------------*/
@import 'normalize.css'; // reset
@import 'mixins'; // Mixins
@import 'variables'; // Variables
/*---------------------------------
-------------- common -------------
---------------------------------*/
@import 'common/general'; //                 general
@import 'common/page-container'; //          page-container
@import 'common/theme-configurator'; //      theme-configurator

/*---------------------------------
------------ elements -------------
---------------------------------*/
@import 'elements/buttons'; //               buttons
@import 'elements/modal'; //                 modal
@import 'elements/form'; //                  form
@import 'elements/card'; //                  card
@import 'elements/progress-bar'; //          progress bar
@import 'elements/ng-select.theme'; //       select
@import 'elements/loader'; //                loader
@import 'elements/alert'; //                 alert
@import 'elements/charts'; //                 charts
@import 'elements/tooltip'; //                 tooltip

@import 'legal'; // legal pages

@import 'custom';
