@import "mixins"; // Mixins
@import "variables"; // Variables
@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,slnt,wdth,wght@8..144,-4,87.5,300;8..144,0,87.5,500;8..144,0,87.5,600;8..144,0,100,400;8..144,0,100,500&display=swap");
.font-primary {
	font-family: "Roboto Flex", sans-serif;
	font-variation-settings: "wght" 400;
}
.font-secondary {
	font-weight: normal;
	font-family: "Roboto Flex", sans-serif;
	font-variation-settings: "wght" 500;
}
.font-alt {
	font-weight: normal;
	font-family: "Roboto Flex", sans-serif;
	font-variation-settings: "wght" 600, "wdth" 87.5;
}
.font-cta {
	font-weight: normal;
	font-family: "Roboto Flex", sans-serif;
	font-variation-settings: "wght" 500, "wdth" 87.5;
}
.font-comment {
	font-style: normal;
	font-family: "Roboto Flex", sans-serif;
	font-variation-settings: "wght" 300, "wdth" 87.5, "slnt" -4;
}

// extentions
.center {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.maxWidth {
	max-width: $max-width;
	margin: 0 auto;
	// display: block;
}

.page_format {
	@extend .maxWidth;
	width: 100%;
	padding: $grid-gap * 2 0 0;
}

.page {
	@extend .page_format;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: calc(100vh - 10em);
	> section {
		&:not(.section-titre) {
			margin-bottom: $grid-gap !important;
		}
		padding: 0 2em;
	}

	.page-title {
		margin: $grid-gap * 2 0 $grid-gap * 0.5 0;

		h1 {
			// color: #fff;
			font-size: 2em;
			margin: 0;
		}
		p {
			font-size: 1em;
			margin: 0;
			color: #fff;
		}
	}
	.flex-title {
		margin: $grid-gap * 2 0 $grid-gap * 0.5 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.page-title {
			margin: 0 0 0 0;
		}
	}
}
.animationFleche {
	animation: 0.3s slideInLeft ease-in-out;
}

.card-shadow {
	transition: box-shadow 0.3s ease-in-out;
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

	@include hover {
		h5 {
			color: $default-primary;
		}
		box-shadow: 0 20px 25px rgba(0, 0, 0, 0.15);
	}
}

/*light bg version */
// .page {
//   > section,
//   .page_section {
//     margin-bottom: 3em !important;
//     .flex-title {
//       margin-top: 0;
//     }
//     .page-title {
//       font-size: 1.3em;
//       margin: 0;
//       padding: 2em 0 1em;
//       h1 {
//         font-size: 1.5em;
//       }
//     }
//   }
// }

/*light bg version End*/

.scrollBox {
	overflow-x: hidden;
	overflow-y: auto;
	// scroll-behavior: smooth;

	&::-webkit-scrollbar-track {
		background-color: $default-grey;
	}
	&::-webkit-scrollbar {
		width: 0.25rem;
		background-color: $default-grey;
	}
	&::-webkit-scrollbar-thumb {
		background-color: rgba($default-text-color, 0.4);
	}
}

.scrollBoxSnap {
	scroll-snap-type: y mandatory;
	section {
		scroll-snap-align: start;
	}
	@extend .scrollBox;
}

.scrollBoxHor {
	@extend .scrollBox;
	overflow-x: scroll;
	overflow-y: hidden;
}

.scrollBoxSnap {
	@extend .scrollBoxSnap;
	scroll-snap-type: x mandatory;
}

@mixin scrollBox($opacity: 1) {
	overflow-x: hidden;
	overflow-y: auto;

	// scroll-behavior: smooth;
	&::-webkit-scrollbar-track {
		opacity: $opacity;
		background-color: $default-grey;
	}
	&::-webkit-scrollbar {
		opacity: $opacity;
		width: 0.25rem;
		background-color: $default-grey;
	}
	&::-webkit-scrollbar-thumb {
		opacity: $opacity;
		background-color: rgba($default-text-color, $opacity);
	}
}

.popin {
	> article {
		max-height: 50vh;
		min-height: 15rem;
		height: auto;
		max-width: 90%;
		min-width: 20rem;
		width: 30rem;
		padding: 4rem 0;

		justify-content: space-between;
		gap: 2rem;
		display: grid;

		> *:not(.close) {
			padding: 0 4em;
		}
		@include hover {
			box-shadow: inherit;
		}
		.close {
			font-size: 1.5em;
			color: $default-dark;
		}
		header {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			h3 {
				color: $default-primary;
				font-size: 1.5em;
			}
		}
		main {
			@include scrollBox(0.5);
		}
	}
}

.hide {
	display: none;
}
