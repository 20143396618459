figure.chart {
  position: relative;
  width: 100%;

  display: grid;
  align-items: center;
  justify-content: center;
  & > svg {
    width: 100%;
    height: auto;
    overflow: visible;
  }
}
.legende {
  padding: 0;
  display: flex;
  flex-direction: column;

  li {
    display: grid;
    grid-template-columns: auto 6em 3em;
    min-height: 3em;
    padding: 0.5em;
    border-radius: 0.5em;
    gap: 0.5em;
    transition: all 0.2s ease-in;
    cursor: pointer;

    &:hover,
    &.active {
      transition: all 0.2s ease-out;
      background-color: $default-grey;
      color: $default-primary;
    }
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    &:nth-child(1) {
      justify-content: flex-start;
      text-transform: capitalize;
    }
    &:nth-child(2) {
      justify-content: flex-end;
    }
    &:nth-child(3) {
      span {
        color: $default-dark;
      }
      span.na {
        font-size: 0.8em;
        color: $default-text-color;
      }
      span.up {
        color: $default-success;
        transform: rotate(-40deg);
        font-size: 1em;
      }
      span.down {
        color: $default-danger;
        transform: rotate(40deg);
        font-size: 1em;
      }
    }
  }
}

//==================================
// chart-pie-saving
//==================================
chart-pie-saving {
  .pieSaving {
    padding: 0 15px;
    path.slice {
      cursor: pointer;
      stroke-width: 2px;
      // fill: $default-primary;
      // &:nth-child(even) {
      //   fill: lighten($default-primary, 10%);
      // }
      &:hover,
      &.active {
        fill-opacity: 1;
      }
    }
  }

  .legende {
    border-top: 2px solid $default-grey;
    margin-top: 3em;
    padding-top: 1em;
    li {
      padding: 10px 15px;
    }
  }
}
@container (width < 390px) {
  chart-pie-saving {
    background-color: red;
    .legende {
      li {
        grid-template-columns: auto 6em;
        > div:nth-child(3) {
          display: none;
        }
        div:first-child {
          position: relative;
          span {
            position: relative;
            max-width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
//==================================
// chart-line-card
//==================================
.chart.lineCard {
  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  width: 100%;
  height: 100%;

  > svg {
    max-height: 100%;
    display: block;
    position: absolute;

    path.line {
      fill: none;
      stroke-width: 2px;
    }
  }
}

//==================================
// chart-line-contract
//==================================
chart-line-contract {
  position: relative;
}
.chart.lineContract {
  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  width: 100%;
  height: 100%;

  > svg {
    max-height: 100%;
    display: block;
    position: absolute;

    path#line {
      stroke-width: 2px;
      fill: none;
      stroke: $default-primary;
      transition: all 0.2s ease-in;
    }
    &.active {
      path#line {
        opacity: 0.5;
      }
      #yAxis,
      #xAxis {
        opacity: 0.6;
      }
      #focus {
        display: block;
      }
    }
  }
  .domain {
    display: none;
  }
}

//==================================
.chart-grid {
  .yAxis {
    .domain {
      display: block;
      opacity: 0.1;
    }
    .tick line {
      opacity: 0.1;
      display: none;
    }
    .tick:first-of-type line {
      opacity: 0.3;
    }
    .tick text {
    }
  }
  .xAxis {
    .domain {
      display: block;
      opacity: 0.1;
    }
    .tick line {
      opacity: 0.1;
      display: none;
    }
  }
  .axis-grid {
    line {
      stroke: $default-dark;
      opacity: 0.1;
    }
    text {
      display: none;
    }
  }
}
#focus {
  line {
    stroke: $default-primary;
    stroke-dasharray: 3, 3;
    // opacity: 0.5;
  }
  circle.marker {
    fill: none;
    stroke: $default-primary;
    stroke-width: 2px;
    r: 6px;
  }

  .test {
    width: 100px;
    height: 100px;
    background-color: red;
    fill: #fff;
    text {
      color: #000;
      fill: #000;
    }
  }

  .toolTipY {
    $toolTipYw: 50px;
    $toolTipYh: 30px;
    $toolTipYp: 6px;

    position: absolute;
    width: $toolTipYw;
    height: $toolTipYh;

    .toolTipY_pointer {
      fill: $default-primary;
      position: absolute;
      width: $toolTipYp;
      height: $toolTipYp;
      transform: translate($toolTipYw, calc(($toolTipYh - $toolTipYp) / 2)) rotate(45deg);
      background-color: $default-primary;
    }
    .toolTipY_bg {
      fill: $default-primary;
      width: $toolTipYw;
      height: $toolTipYh;
    }
    .toolTipY_label {
      width: $toolTipYw;
      height: $toolTipYh;

      p {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        font-size: 0.9em;
        font-weight: 400;
        margin: 0;
        padding: 0;
        color: #fff;
        text-align: right;
      }
    }
  }

  .toolTipX {
    $toolTipXw: 90px;
    $toolTipXh: 30px;
    $toolTipXp: 6px;

    position: absolute;
    width: $toolTipXw;
    height: $toolTipXh;

    .toolTipX_pointer {
      fill: $default-primary;
      position: absolute;
      width: $toolTipXp;
      height: $toolTipXp;
      transform: translate(calc($toolTipXw/ 2), - calc( $toolTipXp / 2)) rotate(45deg);
      background-color: $default-primary;
    }
    .toolTipX_bg {
      fill: $default-primary;
      width: $toolTipXw;
      height: $toolTipXh;
    }
    .toolTipX_label {
      width: $toolTipXw;
      height: $toolTipXh;

      p {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        font-size: 0.9em;
        font-weight: 400;
        margin: 0;
        padding: 0;
        color: #fff;
        text-align: right;
      }
    }
  }

  text.y2 {
    fill: $default-primary;

    text-anchor: middle;
  }
  text.y4 {
    fill: $default-primary;
    text-anchor: middle;
  }
}

//==================================
// chart-area-contract
//==================================
chart-area-contract {
  position: relative;
}
.chart.areaContract {
  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  width: 100%;
  height: 100%;

  > svg {
    max-height: 100%;
    display: block;
    position: absolute;
    path#area {
      fill: $default-primary;
      transition: all 0.2s ease-in;
    }
    &.active {
      path#area {
        opacity: 0.5;
      }
      #yAxis,
      #xAxis {
        opacity: 0.6;
      }
      #focus {
        display: block;
      }
    }
    .domain {
      display: none;
    }
  }
}
//==================================
// chart-stack-bar-earnings
//==================================

chart-stack-bar-earning {
  display: grid;
  width: 100%;
  height: 100%;
  // min-height: 450px;
  justify-content: flex-start;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  figure {
    display: block;
    // width: 100px !important;
    height: 100%;
    min-height: 500px;
    max-width: 150px;
    svg {
      height: 100%;
      transform: translateX(10px);
    }
    // border-right: 2px solid $default-grey;
  }
  #stack {
    rect {
      width: 75px;
      position: absolute;
      cursor: pointer;
      // transform-origin: 50% 50%;

      &.active {
        filter: drop-shadow(1px 1px 0 rgba(0, 0, 0, 0.1));
        // z-index: 100;
        // opacity: 0.5;
        // transform-origin: center;
        // transform: scale(1.2);
        // transform: translate(10px, 0);
        // transform: scale(1.2) translate(-10%, -10%);
      }
    }

    .label {
      position: relative;
      width: 220px;
      // min-height: 40px;

      p {
        color: #000;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;
      }
    }
    line {
      stroke: #000;
    }
    .pos {
      display: relative;
    }
  }

  $pos-color: desaturate($default-success, 15%);
  $neg-color: desaturate($default-danger, 15%);
  // .pos {
  //   rect {
  //     fill: $pos-color;
  //     &:nth-child(even) {
  //       fill: lighten($pos-color, 10%);
  //     }
  //   }
  // }
  // .neg {
  //   rect {
  //     // opacity: 0.8;
  //     fill: $neg-color;
  //     &:nth-child(even) {
  //       fill: lighten($neg-color, 10%);
  //     }
  //   }
  // }
  .domain {
    display: none;
  }
  .yAxis .tick line {
    opacity: 1 !important;
    width: 100px;
    fill: #000;
    stroke: #000;
    stroke-width: 4px;
  }
  .legende {
    margin-top: 0;

    li {
      grid-template-columns: auto 6em;
      div:last-child span {
        color: $default-dark;
        &.pos {
          color: $default-success;
        }
        &.neg {
          color: $default-danger;
        }
      }
    }
  }
}
@container (width < 390px) {
  chart-stack-bar-earning {
    grid-template-columns: 100px 1fr;
    .legende {
      li {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 0;
        div:first-child {
          position: relative;
          span {
            position: relative;
            max-width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        div:last-child {
          text-align: left;
          justify-content: flex-start;
        }
      }
    }
  }
}

///==================================

.card-graph {
  display: grid;
  grid-template-rows: 8em auto;
  > div {
    position: relative;
  }
  > .card-title {
    // h2 {
    //   color: $default-primary;
    // }
    p {
      margin-top: 0.25em;
    }
    border-bottom: 1px solid rgba($default-text-color, 0.3);
  }

  > div:last-child {
    padding: 1em 0;
  }
}
