.toolTipHover,
[toolTipTrigger],
.toolTipTrigger {
	cursor: pointer;
	position: relative;
	@include active {
		z-index: 99999999;
		.tooltip,
		tooltip,
		& + .tooltip,
		& + tooltip {
			opacity: 1;
			visibility: visible;
		}
	}
}

// .tooltip {
// 	background-color: $default-info;
// 	color: #fff;
// 	min-width: 15em;
// 	border-radius: 0.25em;
// 	@extend .font-cta;
// 	border: none;
// 	text-align: center;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	padding: 1em;
// 	// --------------------
// 	z-index: 99999999;
// 	position: absolute;
// 	top: -0.5rem;
// 	left: 50%;
// 	opacity: 0;
// 	visibility: hidden;
// 	transition: opacity 0.2s ease;
// 	transform: translate(-50%, -100%);
// 	&::after {
// 		content: "";
// 		position: absolute;
// 		top: 100%;
// 		left: 50%;
// 		margin-left: -0.5em;
// 		border-width: 0.5em;
// 		border-style: solid;
// 		border-color: $default-info transparent transparent transparent;
// 	}
// }

.charttooltip {
	@extend .tooltip;
	border-width: 0;
	padding: 1em;
	min-width: 10em;
	opacity: 1;
	display: grid;
	grid-template-rows: auto;
	> * {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	> header {
		min-width: 15em;
		white-space: normal;
		h5 {
			font-size: 1em;
			text-transform: uppercase;
		}
	}
	> footer {
		font-size: 1.5em;
	}
	> div {
		padding: 0.5em 0;
		> span:not(.success):not(.danger) {
			width: 50%;
			&:first-child {
				text-align: right;
				color: $default-text-color;
			}
			&:last-child {
				text-align: left;
				padding-left: 0.5em;
			}
		}
		strong {
			margin-left: 0.5em;
		}
	}
	.show {
		display: flex;
	}
	.hide {
		display: none;
	}
	.success {
		color: $default-success;
	}
	.danger {
		color: $default-danger;
	}
}
