.page-container {
  min-height: 100vh;
  padding-left: $offscreen-size;
  @include transition(all 0.2s ease);

  display: grid;
  grid-template-rows: $header-height calc(100vh -  $header-height ) ;

  > .main-content {
    >div:first-child {
      min-height: calc(100vh -  $header-height ) ;
    }
    @extend .scrollBox;
    z-index: 0;
  }
}

//////////////////// ????

.full-container {
  position: absolute;
  top: $header-height;
  left: $offscreen-size;
  right: 0px;
  min-height: calc(100vh - #{$header-height});
  @include transition(all 0.2s ease);

  @include screen-laptop-only {
    padding-left: $collapsed-size;
    left: 0px;
  }

  @include screen-tablet {
    left: 0px;
  }
}

.ng-enter {
  transition: 0.5s;
  opacity: 0;
}

.ng-enter-active {
  opacity: 1;
}

.is-collapsed {
  .page-container {
    padding-left: $collapsed-size;

    @include screen-tablet {
      padding-left: 0px;
    }

    @include screen-laptop-only {
      padding-left: $offscreen-size;
    }
  }

  .full-container {
    left: $collapsed-size;

    @include screen-tablet {
      left: 0px;
    }

    @include screen-laptop-only {
      left: $offscreen-size;
      padding-left: 0px;
    }
  }
}
