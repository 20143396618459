@mixin alert-theme($color) {
  background-color: $color;
}

.alert {
  background-color: $default-danger;
  color: rgba(#000, 0.5);
  padding: 1em;
  margin-top: 1em;
  display: flex;
  align-items: center;
  > span:first-child {
    width: 3em;
    line-height: 0;
  }
  > span:last-child {
    width: 100%;
  }

  &.primary {
    @include alert-theme($default-primary);
  }
  &.danger {
    @include alert-theme($theme-danger);
  }
  &.warning {
    @include alert-theme($default-warning);
  }
  &.success {
    @include alert-theme($default-success);
  }
  &.info {
    @include alert-theme($default-info);
  }
}
